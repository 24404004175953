import { ENV } from '../../src/environments/secret-env';

export const environment = {
  production: false,
  apiBaseUrl: 'https://api.z.genalyte.com',
  appUrl: 'https://operator.z.genalyte.com',
  scanner: {
    resourcesPath: '../../../../../../../../assets/dwt-resources',
    dwtProductKey: ENV.SCANNER_NONPROD_KEY,
    uploadTargetURL: '/apps/operator/documents',
  },
  natsServer: '',
};
